import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/node/work/src/templates/post.template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Recently I bought an iPad so I can live share my notes to my laptop during
screencasts. This is incredibly useful for online meetings, presentations, or
pair programming sessions where I want to show notes to whoever in real-time.`}</p>
    <p>{`In this blog post, I'll explain how I setup screen mirroring from my iPad to a
NixOS based setup. Whether you are using NixOS as your daily driver or just want
to try out screen mirroring on a `}{`*`}{`nix based system, hopefully my experience and
learnings can be helpful for you.`}</p>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <ol>
      <li parentName="ol">{`An iPad with screen mirroring capabilities.`}</li>
      <li parentName="ol">{`A laptop or desktop computer running `}<a parentName="li" {...{
          "href": "https://nixos.org/"
        }}>{`NixOS`}</a>{`.`}</li>
      <li parentName="ol">{`Both devices connected to the same local network`}</li>
    </ol>
    <p>{`Note that for the rest of this tutorial I'm running NixOS 22.11 (Raccoon) and
an iPad (6th gen) running iOS 16.2.`}</p>
    <h2 {...{
      "id": "setting-up-an-airplay-receiver-on-nixos"
    }}>{`Setting up an AirPlay receiver on NixOS`}</h2>
    <p>{`Apple devices canonically talk to each other via `}<a parentName="p" {...{
        "href": "https://www.apple.com/airplay/"
      }}>{`AirPlay`}</a>{`, so in order to
mirror an iPad to NixOS, we need to setup an AirPlay "receiver" server.`}</p>
    <p>{`There are many open source AirPlay receivers, but I chose to use `}<a parentName="p" {...{
        "href": "https://github.com/FDH2/UxPlay"
      }}>{`UxPlay`}</a>{` simply
because it was the first one I came across and it's in `}<inlineCode parentName="p">{`nixpkgs`}</inlineCode>{`.`}</p>
    <p>{`Now unfortunately if we start running the receiver via `}<inlineCode parentName="p">{`nix run
  nixpkgs#uxplay`}</inlineCode>{`, the iPad won't see anything 😓. This is expected though
because the receiver has no way of interacting with the `}<a parentName="p" {...{
        "href": "https://openairplay.github.io/airplay-spec/service_discovery.html"
      }}>{`service discover`}</a>{`
mechanism of AirPlay which is mDNS.`}</p>
    <p>{`The UxPlay README recommends using `}<a parentName="p" {...{
        "href": "https://github.com/lathiat/avahi"
      }}>{`Avahi`}</a>{` for doing mDNS. Luckily `}<a parentName="p" {...{
        "href": "https://github.com/NixOS/nixpkgs/blob/release-22.11/nixos/modules/services/networking/avahi-daemon.nix"
      }}>{`Avahi is
available for NixOS`}</a>{` so we can add it too our `}<inlineCode parentName="p">{`configuration.nix`}</inlineCode>{` as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-nix"
      }}>{`services.avahi = {
  nssmdns = true;
  enable = true;
  publish = {
    enable = true;
    userServices = true;
    domain = true;
  };
};
`}</code></pre>
    <p>{`Now after a `}<inlineCode parentName="p">{`nixos-rebuild switch`}</inlineCode>{` we should be running Avahi`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`$ systemctl status avahi-daemon.service
* avahi-daemon.service - Avahi mDNS/DNS-SD Stack
     Loaded: loaded (/etc/systemd/system/avahi-daemon.service; enabled; preset: enabled)
     Active: active (running) since Tue 2022-12-27 09:12:57 EST; 13s ago
TriggeredBy: * avahi-daemon.socket
   Main PID: 245105 (avahi-daemon)
     Status: "Server startup complete."
         IP: 8.8K in, 8.8K out
         IO: 0B read, 0B written
      Tasks: 1 (limit: 38114)
     Memory: 628.0K
        CPU: 22ms
     CGroup: /system.slice/avahi-daemon.service
             \`-245105 "avahi-daemon: running [spam.local]"

Dec 27 09:12:57 spam avahi-daemon[245105]: New relevant interface lo.IPv4 for mDNS.
Dec 27 09:12:57 spam avahi-daemon[245105]: Network interface enumeration completed.
...
`}</code></pre>
    <p>{`Now if we try running UxPlay again, we should see our AirPlay receiver show up
in the screen mirror.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "886px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "90.18058690744921%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAEDAgQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAHnVzTcbCSWwM4jb//EABsQAAICAwEAAAAAAAAAAAAAAAECABADETFB/9oACAEBAAEFAnZteTJQ4Ia//8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ASv/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAZEAABBQAAAAAAAAAAAAAAAAABABAgMVH/2gAIAQEABj8CGK2Ev//EABsQAQACAwEBAAAAAAAAAAAAAAEAIRARYTFB/9oACAEBAAE/IUD8citiCwgd6bl9gph6x//aAAwDAQACAAMAAAAQyyg8/8QAFhEBAQEAAAAAAAAAAAAAAAAAASEg/9oACAEDAQE/EBkw/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8Qa0qf/8QAHRABAAMAAQUAAAAAAAAAAAAAAQARQTEQIVHh8P/aAAgBAQABPxDCTQlq+qHQO3InqO06TAFZEqmohZ4Jy6n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/368f9a50ff89a22b7e6035f2e9241af2/3fa74/ipad_screenshot.webp 886w"],
            "sizes": "(max-width: 886px) 100vw, 886px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/368f9a50ff89a22b7e6035f2e9241af2/35822/ipad_screenshot.jpg 886w"],
            "sizes": "(max-width: 886px) 100vw, 886px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/368f9a50ff89a22b7e6035f2e9241af2/35822/ipad_screenshot.jpg",
            "alt": "img",
            "title": "my iPad seeing UxPlay!",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`This is great! However if we try to connect we will just end up with a
timeout. What gives?`}</p>
    <p>{`After breaking out Wireshark it was obvious that is was a firewall issue. The
simple remedy to this problem was to set `}<inlineCode parentName="p">{`networking.firewall.enable = false;`}</inlineCode>{`
in the `}<inlineCode parentName="p">{`configuration.nix`}</inlineCode>{`. But this is in general a bad idea and should be
avoided.`}</p>
    <p>{`Instead I went with a uxplay + iptables + bash approach so while the uxplay is
running, iptables will allow incoming traffic to all necessary ports, and on
exit, will close them again.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`#! /usr/bin/env nix-shell
#! nix-shell --quiet -p uxplay -i bash

set -ueo pipefail

## Clear any existing "DROP ME" rules
## ref: https://stackoverflow.com/a/63855690/12393422
while sudo iptables -L -n --line-number | grep "DROP ME" > /dev/null; do
  sudo iptables -D INPUT $(sudo iptables -L -n --line-number | grep "DROP ME" | head -1 | awk '{print $1}');
done


LOCAL_CIDR=\${1:-"192.168.0.0/16"}

open-port-tcp() {
  local port=$1
  echo "Opening tcp port $port from $LOCAL_CIDR ..."
  sudo iptables \\
       -I INPUT \\
       -p tcp \\
       -s $LOCAL_CIDR \\
       --dport $port \\
       -j ACCEPT \\
       -m comment --comment "DROP ME"
}

close-port-tcp() {
  local port=\${1:-0}
  echo "Closing tcp port $port from $LOCAL_CIDR ..."
  sudo iptables \\
       -D INPUT \\
       -p tcp \\
       -s $LOCAL_CIDR \\
       --dport $port \\
       -j ACCEPT \\
       -m comment --comment "DROP ME"
}

open-port-udp() {
  local port=$1
  echo "Opening udp port $port from $LOCAL_CIDR ..."
  sudo iptables \\
       -I INPUT \\
       -p udp \\
       -s $LOCAL_CIDR \\
       --dport $port \\
       -j ACCEPT \\
       -m comment --comment "DROP ME"
}

close-port-udp() {
  local port=\${1:-0}
  echo "Closing udp port $port from $LOCAL_CIDR ..."
  sudo iptables \\
       -D INPUT \\
       -p udp \\
       -s $LOCAL_CIDR \\
       --dport $port \\
       -j ACCEPT \\
       -m comment --comment "DROP ME"
}

open-port-tcp 7100
open-port-tcp 7000
open-port-tcp 7001
open-port-udp 6000
open-port-udp 6001
open-port-udp 7011

# Ensure port closes if error occurs.
trap "close-port-tcp 7100 && \\
      close-port-tcp 7000 && \\
      close-port-tcp 7001 && \\
      close-port-udp 6000 && \\
      close-port-udp 6001 && \\
      close-port-udp 7011
      " EXIT

uxplay -p

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ ipad_screen_mirror_server
Opening tcp port 7100 from 192.168.0.0/16 ...
Opening tcp port 7000 from 192.168.0.0/16 ...
Opening tcp port 7001 from 192.168.0.0/16 ...
Opening udp port 6000 from 192.168.0.0/16 ...
Opening udp port 6001 from 192.168.0.0/16 ...
Opening udp port 7011 from 192.168.0.0/16 ...
using network ports UDP 7011 6001 6000 TCP 7100 7000 7001
using system MAC address xx:xx:xx:xx:xx:xx
Initialized server socket(s)
`}</code></pre>
    <p>{`Here is the `}<a parentName="p" {...{
        "href": "https://gist.github.com/cmrfrd/fe8f61da076f8a4a751bf8fc8cb579a5"
      }}>{`gist`}</a>{` for those interested.`}</p>
    <p>{`Now wrapping all this code into a simple script gives us an easy way to easily
spin up an AirPlay receiver server!`}</p>
    <p>{`If you want to learn more about screen mirroring or NixOS, here are some
additional resources you might find helpful:`}</p>
    <ul>
      <li parentName="ul">{`NixOS documentation (`}<a parentName="li" {...{
          "href": "https://nixos.org/manual/"
        }}>{`https://nixos.org/manual/`}</a>{`)`}</li>
      <li parentName="ul">{`mDNS RFC (`}<a parentName="li" {...{
          "href": "https://www.rfc-editor.org/rfc/rfc6762.html"
        }}>{`https://www.rfc-editor.org/rfc/rfc6762.html`}</a>{`)`}</li>
      <li parentName="ul">{`Publish a service with Avahi on NixOS
(`}<a parentName="li" {...{
          "href": "https://blog.stigok.com/2019/12/09/nixos-avahi-publish-service.html"
        }}>{`https://blog.stigok.com/2019/12/09/nixos-avahi-publish-service.html`}</a>{`)`}</li>
      <li parentName="ul">{`OpenAirPlay Spec (`}<a parentName="li" {...{
          "href": "https://openairplay.github.io/airplay-spec/introduction.html"
        }}>{`https://openairplay.github.io/airplay-spec/introduction.html`}</a>{`)`}</li>
    </ul>
    <p>{`I hope you found this useful, let me know if you have any other tips or tricks
for screen mirroring on NixOS!`}</p>
    <p>{`(ง ͠° ͟ل͜ ͡°)ง`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      